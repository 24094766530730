import React from "react"
import { Avatar, Tooltip } from "antd"
import style from "./SocialSharing.module.scss"

const linkBuilders = {
  facebook: pageUrl => {
    const url = encodeURIComponent(pageUrl)
    return `https://facebook.com/sharer/sharer.php?u=${url}`
  },
  twitter: pageUrl => {
    const url = encodeURIComponent(pageUrl)
    return `https://twitter.com/intent/tweet/?url=${url}`
  },
}

export default ({ pageUrl }) => {
  return (
    <div className={style.wrapper}>
      <Tooltip title="Share on Facebook!">
        <a
          className={style.link}
          href={linkBuilders.facebook(pageUrl)}
          rel="noopener noreferrer"
          target="_blank"
        >
          <Avatar
            className={style.facebook}
            icon="facebook"
            shape="square"
            size="small"
          />
        </a>
      </Tooltip>
      <Tooltip title="Share on Twitter!">
        <a
          className={style.link}
          href={linkBuilders.twitter(pageUrl)}
          rel="noopener noreferrer"
          target="_blank"
        >
          <Avatar
            className={style.twitter}
            icon="twitter"
            shape="square"
            size="small"
          />
        </a>
      </Tooltip>
    </div>
  )
}
