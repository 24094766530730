import React from "react"
import Duotone from "../DuotoneImage/DuotoneImage"
import PropTypes from "prop-types"
import style from "./MinimalHero.module.scss"

const MinimalHero = props => {
  const caption = () => {
    const captionProps = {
      className: style.caption,
      style: {
        color: props.captionColor,
      },
    }

    return <div {...captionProps}>{props.title}</div>
  }

  return (
    <>
      <div
        style={{
          alignContent: `center`,
          backgroundClip: `text`,
          boxShadow: `0 -5px 10px red inset`,
          display: `flex`,
          height: props.height,
          justifyContent: `center`,
          textAlign: `center`,
          width: `100vw`,
        }}
      >
        <Duotone
          alt={props.alt}
          color1={props.color1}
          color2={props.color2}
          fluid={props.fluid}
          height={props.height}
          src={props.image}
        />
        {caption()}
      </div>
    </>
  )
}

MinimalHero.propTypes = {
  alt: PropTypes.string.isRequired,
  captionColor: PropTypes.string,
  color1: PropTypes.string,
  color2: PropTypes.string,
  fluid: PropTypes.bool,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  image: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.node,
  ]),
}

MinimalHero.defaultProps = {
  alt: null,
  captionColor: "#fff",
  color1: null,
  color2: null,
  fluid: false,
  height: "60vh",
  title: false,
}

export default MinimalHero
