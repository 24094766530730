//? comment out unused/undefined values!
// complete list: https://github.com/ant-design/ant-design/blob/master/components/style/themes/default.less

module.exports = {
  //! Colors
  "primary-color": `#4296b4`,
  "info-color": `#263746`,
  // 'success-color': ``,
  // 'processing-color': ``,
  // 'error-color': ``,
  // 'highlight-color': ``,
  // 'warning-color': ``,
  // 'normal-color': ``,
  white: `#fff`,
  black: `#000`,
  //! Backgrounds
  "body-background": `#fff`,
  "component-background": `#fff`,
  //! Fonts
  "font-family": `nimbus-sans`,
  // 'text-color': ``,
  // 'text-color-secondary': ``,
  "font-size-base": `16px`,
  "line-height-base": `1.5`,
  //! Links
  "link-decoration": `none`,
  "link-hover-decoration": `none`,
  // !Layout
  "layout-body-background": `#f0f2f5`,
  "layout-header-background": `#001529`,
  "layout-footer-background": `rgb(34, 34, 34)`,
  "layout-header-height": `64px`,
  "layout-header-padding": `0 50px`,
  "layout-footer-padding": `24px 50px`,
  //! Menu
  // 'menu-highlight-color': ``,
  "menu-item-active-bg": `fadeout(#4296b4, 90%)`,
  // 'menu-item-active-border-width': ``
  //! Table
  "table-row-hover-bg": `#e1e1e1`,
}
