import React from "react"
import { Icon } from "antd"
import Img from "gatsby-image"
import PropTypes from "prop-types"
import style from "./Wistia.module.scss"

function ensureHTTPS(url) {
  return url.includes("https") ? "https:" + url.split(":")[1] : url
}

function ensureIframe(url) {
  if (url.indexOf(`embedType=async`))
    return url.replace(`embedType=async`, `embedType=iframe`)
  return url
}

class Wistia extends React.Component {
  static propTypes = {
    oEmbedUrl: PropTypes.string.isRequired,
    endpoint: PropTypes.string,
    iFrameEmbed: PropTypes.bool,
    style: PropTypes.object,
    loadingImageAlt: PropTypes.string,
    loadingImageFluid: PropTypes.object,
  }

  static defaultProps = {
    endpoint: "https://fast.wistia.com/oembed.json?url=",
    iFrameEmbed: true,
    style: {},
    loadingImageAlt: null,
    loadingImage: null,
  }

  constructor(props) {
    super(props)
    this.state = {
      html: null,
      width: null,
    }
  }

  componentDidMount() {
    this.embed(this.props.oEmbedUrl)
    this.props.oEmbedUrl.split("&").forEach(param => {
      if (param.startsWith("videoWidth=")) {
        this.setState({ width: parseInt(param.split("=")[1]) })
        return
      }
    })
  }

  embed = url => {
    if (this.props.iFrameEmbed) {
      url = ensureIframe(url)
    }
    const URI =
      ensureHTTPS(this.props.endpoint) + encodeURIComponent(ensureHTTPS(url))
    fetch(URI).then(response => {
      response.json().then(data => {
        this.setState({ html: data.html })
      })
    })
  }

  loadingContent = _ => {
    if (this.props.loadingImageFluid !== null) {
      return (
        <Img
          alt={this.props.loadingImageAlt}
          fluid={this.props.loadingImageFluid}
        />
      )
    } else {
      return <Icon type="loading" className={style.icon} />
    }
  }

  render() {
    return (
      <div
        className={style.video}
        style={{
          margin: `auto`,
          maxWidth: this.state.width,
          ...this.props.style,
        }}
      >
        {this.state.html === null ? (
          this.loadingContent
        ) : (
          <div dangerouslySetInnerHTML={{ __html: this.state.html }} />
        )}
      </div>
    )
  }
}

export default Wistia
